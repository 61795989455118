import $ from "jquery";
import { logFactory, isCompExists, vendors } from "GlobalSite";

const compName = "comp_aem_hp-card-comparator";
const Log = logFactory(compName);
const { log } = Log;
const {
  splide: { Splide, fr },
} = vendors;

log("--->");

$(function load() {
  if (!isCompExists(compName, Log)) {
    return;
  }

  const $component = $(".hp-comparator");

  // mobile carousel initialisation
  const mobileSliderOption = {
    gap: "1rem",
    mediaQuery: "min",
    breakpoints: {
      768: {
        destroy: true,
      },
    },
    i18n: fr,
  };

  const $sliders = $component.find(".splide");

  for (const slider of $sliders) {
    new Splide(slider, mobileSliderOption).mount();
  }
});
